import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Popups, { ShowSuccess, ShowError } from './Modules/Popups';

import Account_Header from './Modules/Account_Header';
import Account_Navigation from './Modules/Account_Navigation';

import empty_image from "../images/empty.png";
import image_loading from "../images/loading2.gif";


const Account = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [InvalidToken, SetInvalidToken] = React.useState(false);

    const [Id, SetId] = React.useState(0);
    const [Username, SetUsername] = React.useState("Username");
    const [Avatar, SetAvatar] = React.useState("Avatar");
    const [Balance, SetBalance] = React.useState(0);

    const [SubscriptionsParsed, SetSubscriptionsParsed] = React.useState(false);
    const [SubscriptionsArray, SetSubscriptionsArray] = React.useState([]);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    useEffect(()=> {
        axios.post('https://api.0xcheats.su/Account/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true") {

                //Аккаунт
                SetId(json.id);
                SetUsername(json.username);

                if (json.avatar.length == 0)
                    SetAvatar("https://api.0xcheats.su/Images/Avatars/invalid.jpg");
                else
                    SetAvatar("https://api.0xcheats.su/Images/Avatars/" + json.avatar);

                SetBalance(json.balance);

                //Подписки
                SetSubscriptionsParsed(true);
                SetSubscriptionsArray(json.subscriptions);
                setCookie("subscriptions_count", json.subscriptions.length, { path: "/" });

                //Кодер
                if (json.is_coder)
                    setCookie("is_coder", true, { path: "/" });
                else
                    removeCookie("is_coder", { path: "/" });

                //Партнёр
                if (json.is_partner)
                    setCookie("is_partner", true, { path: "/" });
                else
                    removeCookie("is_partner", { path: "/" });

                //Реселлер
                if (json.is_reseller)
                    setCookie("is_reseller", true, { path: "/" });
                else
                    removeCookie("is_reseller", { path: "/" });

                //Агент поддержки
                if (json.is_supporter)
                    setCookie("is_supporter", true, { path: "/" });
                else
                    removeCookie("is_supporter", { path: "/" });
            }
            else if (json.failed != null) {
                SetInvalidToken(true);
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }, [])
    if (InvalidToken) {
        removeCookie("token", { path: "/" });
        removeCookie("is_coder", { path: "/" });
        removeCookie("is_reseller", { path: "/" });
        return <Navigate to="/auth" replace />;
    }

    function Download(identifier) {
        document.getElementById("download-" + identifier).style.display = 'none';
        document.getElementById("download-" + identifier + "-wait").style.display = 'flex';

        axios({
            method: 'post',
            url: 'https://api.0xcheats.su/Account/Download',
            data: qs.stringify({ 'token': cookies.token, 'identifier': identifier }),
            responseType: 'blob'
        })
        .then(function(response)
        {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', identifier + '_launcher.exe');
            document.body.appendChild(link);
            link.click();
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            document.getElementById("download-" + identifier).style.display = 'flex';
            document.getElementById("download-" + identifier + "-wait").style.display = 'none';
        });
    }
    function Subscriptions() {
        if (SubscriptionsParsed) {
            return SubscriptionsArray.map((subscription) =>
            <div key={subscription.identifier} className="product-wrapper">
                <div className="product">
                    <img src={"https://api.0xcheats.su/Images/Games/" + subscription.identifier +"/logo.jpg"} alt="" draggable="false"/>

                    {subscription.scripts == 1 && (
                        <div style={{ position: 'relative', textAlign: 'right', zIndex: '2' }}>
                            <a href={"https://wiki.0xcheats.su/" + subscription.identifier} target="_blank" style={{ display: 'inline-block', margin: '15px 10px', borderRadius: '25px', background: '#DDA44D', padding: '10px', fontWeight: '700', fontSize: '16px' }}>{t('account_wiki')}</a>
                            <Link to={"/games/" + subscription.identifier + "/scripts"} style={{ display: 'inline-block', margin: '15px 0px', borderRadius: '25px', background: '#DDA44D', padding: '10px', fontWeight: '700', fontSize: '16px' }}>{t('account_scripts')}</Link>
                        </div>
                    )}

                    <div className="info">
                        <div className="text">
                            { subscription.hours == "Expired" ? <span className="status-text inactive-red">{ subscription.hours }</span> : "" }
                            { subscription.hours == "Freezed" || subscription.hours == "Updating" || subscription.hours == "Disabled" ? <span className="status-text inactive">{ subscription.hours }</span> : "" }
                            { subscription.hours != "Expired" && subscription.hours != "Freezed" && subscription.hours != "Updating" && subscription.hours != "Disabled" ? <span className="status-text active">{ subscription.hours }</span> : "" }
                            <p className="name">{ subscription.name }</p>
                        </div>
                        { subscription.hours == "Expired" ? <Link to={"/games/" + subscription.identifier} className="status-view inactive-red"><i className="icon expired"></i></Link> : "" }
                        { subscription.hours == "Freezed" || subscription.hours == "Updating" || subscription.hours == "Disabled" ? <div className="status-view inactive"><i className="icon freezed"></i></div> : "" }
                        { subscription.hours != "Expired" && subscription.hours != "Freezed" && subscription.hours != "Updating" && subscription.hours != "Disabled" ? <div id={"download-" + subscription.identifier} className="status-view active" onClick={() => Download(subscription.identifier)}><i className="icon download"></i></div> : "" }
                        <div id={"download-" + subscription.identifier + "-wait"} style={{display: "none"}} onClick={() => Download(subscription.identifier)}><img src={image_loading}/></div>
                    </div>
                </div>
            </div>
            );
        }
        else {
            if (cookies.subscriptions_count != null) {
                return (
                    <>
                      { Array.from(Array(Number(cookies.subscriptions_count)).keys()).map(i => (
                        <div key={i} className="product-wrapper">
                            <div className="product loading"></div>
                        </div>
                      )) }
                    </>
                );
            }
            else {
                return (
                <>
                    <div className="product-wrapper">
                        <div className="product loading"></div>
                    </div>

                    <div className="product-wrapper">
                        <div className="product loading"></div>
                    </div>

                    <div className="product-wrapper">
                        <div className="product loading"></div>
                    </div>
                </>
                );
            }
        }
    }


    return (
    <>
        <Popups/>
        <div className="mobile-menu-overlay">
            <div className="input activate-key">
                <p className="btn black">Активировать</p>
                <input type="text" placeholder="Введите код активации"/>
            </div>
            {/*<div className="payment-block">
                <div className="balance">
                    <a href="" className="btn black go-up-balance">Пополнить</a>
                    <span>50 000 <b>руб</b></span>
                </div>
                <div className="pay"><i className="icon payment"></i></div>
            </div>*/}
        </div>

        <section id="history" className="profile-page">
            <Account_Navigation active={"subscriptions"}/>
            <div className="container">
                <div className="content">
                    <Account_Header username={Username} id={Id} avatar={Avatar} balance={Balance}/>
                    <div className="hl-reson"></div>
                    <div className="pre-title no-m fadeout">
                        <i className="icon products"></i>{t('account_title')}
                        {/*<div><p className="activate-sub">Найдено подписок <span>{ SubscriptionsArray.length }</span><i className="icon fire"></i></p></div>*/}
                    </div>
                    <div className="history-buy no-m fadeout">
                        <Subscriptions/>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
};
export default Account;